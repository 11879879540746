/* AdminDashboard.css */

.container-fluid.dashboard {
  background-color: #f8f9fa;
  min-height: 100vh;
  padding: 20px;
}

.card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.card h3 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #333333;
  display: flex;
  align-items: center;
}

.card .icon {
  margin-right: 10px;
  color: #007bff;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-md-3,
.col-md-9 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 768px) {
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
}

.card.w-75 {
  width: 75%;
  margin: auto;
}

.m-3 {
  margin: 1rem !important;
}

.p-3 {
  padding: 1rem !important;
}

h3 {
  font-size: 1.25rem;
  font-weight: 500;
  color: #212529;
}

h3:not(:last-child) {
  margin-bottom: 1rem;
}

/* AdminMenu component styles */
.admin-menu {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
}

.admin-menu ul {
  list-style: none;
  padding: 0;
}

.admin-menu ul li {
  margin-bottom: 10px;
}

.admin-menu ul li a {
  color: #333333;
  text-decoration: none;
  font-size: 16px;
}

.admin-menu ul li a:hover {
  color: #007bff;
}
