/* Import Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");

/* General Styling */
body {
  font-family: "Poppins", sans-serif;
}

/* Container Styling */
.container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

/* Heading Styling */
h5 {
  font-family: "Playfair Display", serif;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

/* Flexbox Layout for Blog Cards */
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center-align the items horizontally */
  align-items: stretch; /* Make all items the same height */
  gap: 20px; /* Space between the cards */
}

.card {
  flex: 1 1 calc(33.333% - 40px); /* 3 cards per row with space between */
  box-sizing: border-box;
  border: 1px solid #e3e3e3;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 992px) {
  .card {
    flex: 1 1 calc(50% - 40px); /* 2 cards per row with space between */
  }
}

@media (max-width: 576px) {
  .card {
    flex: 1 1 100%; /* 1 card per row */
  }
}

.card-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between; /* Distribute space between elements */
  align-items: center; /* Center-align child elements */
}

.card-body > *:not(:last-child) {
  margin-bottom: 10px; /* Space between child elements */
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.card-text {
  font-size: 1rem;
  color: #555;
}

.btn-primary {
  background-color: #007bff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  align-self: center; /* Center-align the button within the card */
}

.btn-primary:hover {
  background-color: #0056b3;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
  margin-bottom: 10px; /* Space between spinner and text */
}

.loading-text {
  font-size: 0.75rem; /* Smaller font size for the loading text */
}

@media (max-width: 768px) {
  .card-title {
    font-size: 1.25rem;
  }

  .card-text {
    font-size: 0.875rem;
  }

  .btn-primary {
    font-size: 0.875rem;
    padding: 8px 16px;
  }

  h5 {
    font-size: 1.75rem;
  }

  .spinner-border {
    width: 2rem;
    height: 2rem;
  }

  .loading-text {
    font-size: 0.75rem; /* Ensure smaller font size on smaller screens as well */
  }
}

@media (max-width: 576px) {
  .card-title {
    font-size: 1rem;
  }

  .card-text {
    font-size: 0.75rem;
  }

  .btn-primary {
    font-size: 0.75rem;
    padding: 6px 12px;
  }

  h5 {
    font-size: 1.5rem;
  }

  .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }

  .loading-text {
    font-size: 0.75rem; /* Ensure smaller font size on smallest screens as well */
  }
}
