@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

/* ===================================
========= register page  */
.form-container {
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  flex-direction: column;
  background-color: #fde4cf; /* fallback color */
  background-image: linear-gradient(0deg, #fde4cf 0%, #e0c3fc 100%);
}

.form-container form {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;
  background-color: #fff;
}

.form-container form .title {
  text-align: center;
  margin-bottom: 15px;
  font-weight: bold;
  font-family: "Playfair Display", serif;
}

.form-container form input {
  border: none;
  border-bottom: 1px solid #000;
  border-radius: 0;
}

.form-container form button {
  border: 1px solid black;
  border-radius: 0;
  background-color: #000;
  color: white;
  width: 250px;
}

.form-container form button:hover {
  border: 1px solid black;
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #434343,
    #000000
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #434343,
    #000000
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.icon-space {
  margin-left: 10px; /* Adjust the value to increase or decrease space */
}

.forgot-btn {
  background-color: #434343a4 !important;
  border: 0 !important;
}

.forgot-btn:hover {
  color: white !important;
}

/* Add this to your Register.css file */

.important-message {
  font-family: "Nunito", sans-serif;
  display: block;
  margin-top: 5px;
  color: #ff0000; /* or any other color you prefer */
  font-size: 0.7rem;
}
