.dashboard {
  padding: 20px;
  background: linear-gradient(to right, #ffecd2, #fcb69f);
}

.admin-panel {
  background-color: #ffe3e3;
  padding: 10px;
  border-radius: 5px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.card h3 {
  color: #333333;
  font-family: "Arial", sans-serif;
  margin-bottom: 10px;
}

.icon {
  color: #f77a52;
  margin-right: 10px;
}

.container-fluid {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
}

.row {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items at the top */
}

.container {
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
  top: 0;
}

.col-md-3,
.col-md-9 {
  padding: 10px;
}

.order-box {
  display: flex;
  flex-direction: column;
  gap: 0; /* No gaps between order boxes */
}

.product-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%; /* Ensuring all product boxes are the same size */
}

.product-box img {
  width: 100px;
  height: 100px;
}

.product-details {
  flex-grow: 1;
  padding-left: 10px;
}

@media (max-width: 768px) {
  .col-md-3,
  .col-md-9 {
    padding: 5px;
  }
  .card {
    width: 100%;
  }
}
