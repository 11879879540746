.dashboard {
  padding: 20px;
}

.user-card {
  margin-bottom: 20px;
  padding: 10px; /* Reduced padding */
  background-color: #fff;
  border-radius: 5px;
}

.user-card .table {
  margin-bottom: 5px; /* Reduced margin */
}

.user-card .container {
  padding: 5px; /* Reduced padding */
}

.user-card h6 {
  margin-bottom: 5px; /* Reduced margin */
}

.user-card ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0; /* Reduced margin */
}

.user-card ul li {
  padding: 2px 0; /* Reduced padding */
}
